export const items = [
  {
    role: 'student',
    title: 'Lernende',
    icon: 'mdi-account',
    group: 'apps',
    children: [
      {
        title: 'Noten',
        group: 'apps',
        children: [
          {
            title: 'Erfahrungsnoten',
            icon: 'mdi-circle-medium',
            to: 'experience-marks',
          },
          {
            title: 'Zwischenbericht',
            icon: 'mdi-circle-medium',
            to: 'quarter-marks',
          },
          {
            title: 'Semesternoten',
            icon: 'mdi-circle-medium',
            to: 'semester-marks',
          },
          {
            title: 'Abschlussnoten',
            icon: 'mdi-circle-medium',
            to: 'final-marks',
          },
        ],
      },
      {
        title: 'Stundenplan',
        icon: 'mdi-chevron-right',
        to: 'AddQuestions',
      },
      {
        title: 'Präsenzkontrolle',
        icon: 'mdi-chevron-right',
        to: 'presence',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
    ],
  },

  //   {
  //     subheader: "Apps",
  //     icon: "mdi-apps-box",
  //     divider: true
  //   },

  {
    role: 'parent',
    title: 'Eltern',
    icon: 'mdi-account-supervisor-circle',
    group: 'apps',
    children: [
      {
        title: 'Kindern',
        icon: 'mdi-chevron-right',
        to: 'children',
      },
      {
        title: 'Präsenzkontrolle',
        icon: 'mdi-chevron-right',
        to: 'presence',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
    ],
  },
  {
    role: 'company',
    title: 'Praktikumsbetriebe',
    icon: 'mdi-domain',
    group: 'apps',
    children: [
      {
        title: 'Praktikanten ',
        icon: 'mdi-chevron-right',
        to: 'interns',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
      {
        title: 'Praxisnoten',
        icon: 'mdi-chevron-right',
        to: 'intern-marks',
      },
    ],
  },

  {
    role: 'author',
    title: 'Autoren',
    icon: 'mdi-account-tie',
    group: 'apps',
    children: [
      /*{
        title: 'Noten',
        group: 'apps',
        children: [
          {
            title: 'Erfahrungsnoten',
            icon: 'mdi-circle-medium',
            to: 'experience-marks-pma',
          },
          {
            title: 'Zwischenbericht',
            icon: 'mdi-circle-medium',
            to: 'quarter-marks-pma',
          },
          {
            title: 'Semesternoten',
            icon: 'mdi-circle-medium',
            to: 'semester-marks-pma',
          },
          {
            title: 'Noteneingabe',
            icon: 'mdi-circle-medium',
            to: 'final-marks-pma',
          },
        ],
      },*/
      {
        title: 'Fragen',
        icon: 'mdi-chevron-right',
        to: 'addQuestions',
        group: 'apps',
        children: [
          {
          title: 'Mündlich',
            icon: 'mdi-circle-medium',
            to: 'questionsList',
          },
        ]         
      },
      /*
      {
        title: 'Abwesenheiten',
        icon: 'mdi-chevron-right',
        to: 'absence',
      },
      {
        title: 'Klassen',
        icon: 'mdi-chevron-right',
        to: 'courses',
      },
      {
        title: 'Präsenzkontrolle',
        icon: 'mdi-chevron-right',
        to: 'presence-pma',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },*/
    ],
  },
]
